import * as React from "react";
import { Link } from "react-router-dom";
import Banner from "~/components/Banner";
import useCurrentTeam from "~/hooks/useCurrentTeam";
import usePolicy from "~/hooks/usePolicy";
import isCloudHosted from "~/utils/isCloudHosted";

type TrialProps = React.ComponentProps<typeof Banner> & {
  className?: string;
  daysLeftInTrial: number;
};

function BannerTrialWarning({ daysLeftInTrial, ...rest }: TrialProps) {
  if (isCloudHosted) {
    return (
      <Banner {...rest}>
        Your free trial is almost over, there{" "}
        {daysLeftInTrial === 1
          ? "is just 1 day"
          : `are just ${daysLeftInTrial} days`}{" "}
        left – please <Link to="/settings/billing">add payment details</Link> to
        avoid interruption for your team.
      </Banner>
    );
  }

  return (
    <Banner {...rest}>
      Your trial license period expires in{" "}
      {daysLeftInTrial === 1 ? "just 1 day" : `${daysLeftInTrial} days`} –
      please <a href="email:priority@getoutline.com">contact support</a> to
      arrange a production license.
    </Banner>
  );
}

type Props = React.ComponentProps<typeof Banner> & {
  className?: string;
  daysLeftInLicense: number;
};

export function BannerLicenseWarning({ daysLeftInLicense, ...rest }: Props) {
  const team = useCurrentTeam();
  const can = usePolicy(team);

  if (isCloudHosted || !can.update) {
    return null;
  }

  return (
    <Banner {...rest}>
      Your license expires in{" "}
      {daysLeftInLicense === 1 ? "just 1 day" : `${daysLeftInLicense} days`} –
      please <a href="email:priority@getoutline.com">contact support</a> to
      arrange renewal.
    </Banner>
  );
}

export default BannerTrialWarning;
